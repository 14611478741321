<template>
    <div class="form-container">
        <div class="form-flex">
        <form @submit.prevent="submitForm" class="form">
            <div class="form-wrapper">
                <div class="col">
                    <label class="label1" for="first-name">First Name*</label>
                        <input type="text" v-model="firstName" required class="input1">
                    <label class="label1" for="last-name">Last Name*</label>
                        <input type="text" v-model="lastName" required class="input1">
                    <label class="label1" for="email">Email*</label>
                        <input type="email" v-model="email" required class="input1">
                    <label class="label1" for="phone">Phone</label>
                        <input type="tel" v-model="phone" @input="acceptNumber" class="input1">
                </div>
                <div class="col">
                    <label class="label1" for="message">Message*</label>
                        <textarea type="message" v-model="message" required class="input2"></textarea>
                </div>
            </div>
        
            <p class="error" v-if="displayError">*Please complete all required fields!</p>
            <p class="error" v-if="displayConfirmation">Thank you for contacting me. I will get back to you soon!</p>

            <button class="button" type="submit">Submit</button>
        </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const firstName = ref('');
const lastName = ref('');
const phone = ref('');
const email = ref('');
const message = ref('');
const displayError = ref(false);
const displayConfirmation = ref(false);

const webhook = 'https://hooks.zapier.com/hooks/catch/19232705/2rh1j75/';

function acceptNumber() {
    const x = phone.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}

function submitForm() {
    displayError.value = false;
    displayConfirmation.value = false;

    if (firstName.value && lastName.value && message.value && email.value) {
        const dateStamp = new Date().toISOString();
        console.log('Form Submission:', { firstName: firstName.value, dateStamp });

        fetch(webhook, {
            method: 'POST',
            body: JSON.stringify({
                firstName: firstName.value,
                lastName: lastName.value,
                phone: phone.value,
                email: email.value,
                message: message.value,
                dateStamp: dateStamp
            }),
        })
            .then(() => {
                displayConfirmation.value = true;
                firstName.value = '';
                lastName.value = '';
                phone.value = '';
                email.value = '';
                message.value = '';
            })
            .catch(() => {
                displayError.value = true;
            });
    } else {
        displayError.value = true;
    }
}
</script>

<style scoped>
@media screen and (max-width: 700px) {
    .form-wrapper {
        flex-direction: column !important;
        gap: 0 !important;
    }

    .input2 {
        min-height: 200px;
    }

    .col {
        width: 100% !important;
    }

    button {
        width: 200px !important;
        font-size: 17px !important;
        margin-top: -1em !important;
    }
}

.error {
    margin: -1em 0;
    color: rgb(166, 4, 4);
    font-weight: 500;
}

.label1 {
    text-align: left;
    margin-top: 1.5em;
    font-weight: 500;
    line-height: 1.8em;
}

button {
    width: 300px;
    background-color: black;
    border: none;
    font-size: 20px;
    font-weight: 500;
    color: white;
    font-family: 'lexend';
    padding: .6em 1em;
    border-radius: 8px;
}

button:hover {
    background-color: #528fc5;
}

.input2 {
    background-color: rgba(144, 185, 228, 0.118);
    border: 1px solid #5a9db686;
    border-radius: 5px;
    padding: .5em;
    width: auto;
    height: 100%;
}

.input1 {
    background-color: rgba(144, 185, 228, 0.118);
    border: 1px solid #5a9db686;
    border-radius: 5px;
    padding: .5em;
    width: auto;
    min-height: 30px;
    font-size: 16px;
}

.col {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.form-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 40px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
}

.form-flex {
    width: 100%;
    max-width: 1300px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 .9em;
}
</style>