<template>
  <div class="projects-container">
    <div class="projects-flexbox">
      <router-link :to="{ path: '/design/project-liberty'}">
        <div class="row">
          <div class="col4">
            <img
              :src="Liberty"
              class="cover"
            >
          </div>
          <div class="col3">
            <div class="text">
              <div class="header3">
                Liberty Home Mortgage Corp.
              </div>
              <div class="header4">
                Website UI design & build (Nuxt.js & Storyblok)
              </div>
              <div class="year">
                2024
              </div>
            </div>
          </div>
        </div>
      </router-link>
      
      <router-link :to="{ path: '/design/project-aunt-nees'}">
        <div class="row">
          <div class="col4">
            <img
              :src="AuntNees"
              class="cover"
            >
          </div>
          <div class="col3">
            <div class="text">
              <div class="header3">
                Aunt Nees Detroit
              </div>
              <div class="header4">
                Website UI redesign
              </div>
              <div class="year">
                2022
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link :to="{ path: '/design/project-merit'}">
        <div class="row">
          <div class="col4">
            <img
              :src="Merit"
              class="cover"
            >
          </div>
          <div class="col3">
            <div class="text">
              <div class="header3">
                Merit Goodness Detroit
              </div>
              <div class="header4">
                Campaign design & digial assets
              </div>
              <div class="year">
                2021
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link :to="{ path: '/design/project-bon-appetit'}">
        <div class="row">
          <div class="col4">
            <img
              :src="BonAppetit"
              class="cover"
            >
          </div>
          <div class="col3">
            <div class="text">
              <div class="header3">
                Bon Appétit Cuisine
              </div>
              <div class="header4">
                Branding & menu design
              </div>
              <div class="year">
                2023
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="projects-flexbox2">
      <div class="header" id="other">
        Other Design Projects
      </div>
      <div class="grid">
        <div class="square-border" v-for="other in others"
        :key="other.id"
        @click="openPreview(other)">
          <div class="square">
              <img
                :src="other.image"
                class="proj-image"
              >
              <div class="image-header">
                {{ other.header }}
              </div>
              <div class="image-subheader">
                {{ other.subheader }}
              </div>
          </div>
        </div>
      </div>
  </div>

  <div
      v-if="isPreviewOpen"
      class="modal-overlay"
      @click.self="closePreview"
    >
      <div class="modal-content">
        <button class="close-button" @click="closePreview">Close x</button>
        <img :src="selectedOther.image" alt="Preview Image" class="modal-image" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'; 
import Liberty from '@/assets/img/liberty1.jpg';
import AuntNees from '@/assets/img/aunt-nees-digital-grey.png'
import Merit from '@/assets/img/merit-cover.jpg'
import BonAppetit from '@/assets/img/bon-appetit-cover.png'

import Mental from '@/assets/img/consider-mental-health-cover.jpg'
import Partisanship from '@/assets/img/consider-partisanship-cover.jpg'
import Tuff from '@/assets/img/tuff-cover.jpeg'
import Cover from '@/assets/img/book-cover-folktale.jpg'
import Food from '@/assets/img/foodseries-cover.jpg'
import Food1 from '@/assets/img/foodseries1.jpg'
import Food2 from '@/assets/img/foodseries2.jpg'
import Food4 from '@/assets/img/foodseries4.jpg'

const others = [
    {
        header: "TUFF | The Uniform Funding Foundation",
        subheader: "501c(3) Non-Profit Event Brochure Design",
        image: Tuff
    },
    {
        header: "Consider Magazine | Mental Health Issue",
        subheader: "Magazine Cover Design",
        image: Mental
    },
    {
        header: "Consider Magazine | Partisanship Issue",
        subheader: "Magazine Cover Design",
        image: Partisanship
    },
    {
        header: "Korean Folktale Cover",
        subheader: "Book Cover Design",
        image: Cover
    },
    {
        header: "Zine | Mukbang Culture",
        subheader: "Magazine Design",
        image: Food
    },
    {
        header: "Zine | Mukbang Culture",
        subheader: "Magazine Design",
        image: Food1
    },
    {
        header: "Zine | Mukbang Culture",
        subheader: "Magazine Design",
        image: Food2
    },
    {
        header: "Zine | Mukbang Culture",
        subheader: "Magazine Design",
        image: Food4
    }
]

const isPreviewOpen = ref(false); 
const selectedOther = ref(null); 

function openPreview(other) {
  selectedOther.value = other;
  isPreviewOpen.value = true;
}

function closePreview() {
  isPreviewOpen.value = false;
}
</script>

<style scoped>

@media screen and (min-width: 800px) and (max-width: 1100px) {

  .grid {
    grid-template-columns: 1fr 1fr !important;
    gap: 40px !important;
  }

  .proj-image {
    height: 600px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .proj-image {
    height: 590px !important;
  }
}

@media screen and (max-width: 600px) {
  .proj-image {
    height: 530px !important;
  }

  .image-subheader {
    margin-top: 0.2em !important;
  }

}

@media screen and (max-width: 800px) {
  .col4:hover {
    border: none !important;
  }

  .square-border:hover {
    border: none !important;
  }
  
  .header {
    font-size: 35px !important;
    font-weight: 400 !important;
  }

  .image-header {
    font-weight: 800 !important;
    font-size: 23px !important;
  }

  .grid {
    grid-template-columns: 1fr !important;
    gap: 40px !important;
  }

  .row {
    flex-direction: column !important;
    gap: 18px !important;
  }

  .projects-container {
    padding: 0 !important;
  }

  .cover {
    margin: 0 !important;
    width: 100% !important;
  }

  .col3 {
    width: 100% !important;
  }

  .projects-flexbox {
    gap: 25px !important;
  }

  .projects-flexbox2 {
    padding: 2em .7em 8em .7em !important;
  }

  .text {
    gap: 8px !important;
    padding: 0 .7em !important;
  }

  .header3 {
    text-align: left !important;
    font-size: 25px !important;
  }

  .header4 {
    text-align: left !important;
    font-size: 17px !important;
  }

  .modal-image {
    max-width: 95vh !important;
    width: 100% !important;
  }

  .modal-content {
    max-height: none !important;
    height: 85vh !important;
    justify-content: center !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1294px) {
  .header3 {
    text-align: left !important;
    font-size: 35px !important;
  }

  .header4 {
    text-align: left !important;
    font-size: 19px !important;
  }

  .row {
    gap: 10px !important;
  }
}

@media screen and (max-width: 1294px) {

  .col4::after {
    width: 0px !important;
  }

  .col4 {
    width: 100% !important;
  }

  .year {
    text-align: left !important;
    margin-top: -.5em !important;
  }
}

.year {
    text-align: right;
    font-family: 'homemade';
    font-size: 21px;
    margin-top: -1em;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

/* Modal Content */
.modal-content {
  background: black;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  max-height: 95vh;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.modal-image {
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  max-width: 950px;
  height: auto;
  object-fit: cover;
}

.close-button {
  background: transparent;
  border: 1px solid #ff5c5c;
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background: #ff3232;
}

.square-border:hover {
  border: 5px dotted black;
  transition: .3s ease;
  padding: .8em;
}

.image-subheader {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: .5em;
}

.image-header {
    text-align: center;
    font-size: 30px;
    font-family: 'zilla';
    font-weight: 500;
    margin-top: .8em;
}

.proj-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-shadow: 1px 1px 40px rgba(28, 47, 98, 0.5);
    height: 500px;
}

.square {
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; 
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 60px 55px;
    width: 100%;
    max-width: 1300px;
}

.header {
    font-family: 'homemade';
    font-size: 40px;
    margin: .5em 0;
    color: black;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 1em;
}

.header4 {
    text-align: right;
    font-size: 20px;
    font-family: 'lexend';
    font-weight: 500;
}

.header3 {
    text-align: right;
    font-size: 50px;
    font-family: 'zilla';
    font-weight: 800;
}


.text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.col3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 30%;
}

.col4::after {
  content: '';
  width: 40px;
  height: 6px;
  background-color: black;
  margin-left: 55.3em;
  z-index: -10;
  transition: width .5s ease, transform 1s ease;
  transform-origin: left center; 
  position: absolute;
}

.col4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
}


.col4:hover::after {
  width: 85px; 
}

.col4:hover {
    border: 5px dotted black;
    transition: .3s ease;
}

.cover {
    width: 96.5%;
    margin: 1em;
    box-shadow: 1px 1px 40px rgba(28, 47, 98, 0.3);
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    height: 100%;
    width: 100%;
}

.projects-flexbox2 {
    width: 100%;
    max-width: 1300px;
    padding: 2em .7em 15em .7em;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.projects-flexbox {
    width: 100%;
    max-width: 1300px;
    padding: 7em .7em 3em .7em;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 .7em;
}

</style>