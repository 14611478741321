<template>
    <div class="footer-top">
    <div class="footer-container">
        <div class="footer-width">
            <router-link :to="{ path: '/'}">
                <div class="name">
                Rinette Korea
                </div>
            </router-link>
          <div class="footer-items">
            <div class="col">
                <router-link :to="{ path: '/design'}"><p>Design</p></router-link>
                <router-link :to="{ path: '/fine-arts'}"><p>Fine Arts</p></router-link>
                <router-link :to="{ path: '/resume'}"><p>Resume Download</p></router-link>
            </div>
          </div>
        </div>
        <div class="copyright">
            <p1>©Rinette Korea 2024. All rights reserved. Email me at <a href="mailto:rkorea@artdillo.org"><u>rkorea@artdillo.org</u></a>. Please request permission before using any assets. Will respond to TEXT at <a href="tel:+17146441375"><u>(714) 644-1375</u></a>.</p1>
        </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

@media screen and (min-width: 200px ) and (max-width: 449px) {
    .footer-items {
        flex-direction: column !important;
        width: 100% !important;
        gap: 10px !important;
    }

    .socials {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .col {
        max-width: 100% !important;
        align-items: center;
        gap: 0.4em !important;
    }
}

@media screen and (max-width: 700px) {
    .footer-width {
        flex-direction: column !important;
        gap: 30px ;
    }

    .copyright {
        text-align: center;
    }

    .col {
        align-items: center !important;
    }
}

 a:hover {
    text-shadow: none !important;
    color: rgb(106, 115, 156) !important; 
 }

button {
    border-radius: 10px;
    padding: .8em 1em;
    font-family: 'Metropolis-Bold';
    font-size: 15px;
    margin-top: 1em;
    background-color: #ff9f9f;
    border: none;
    color: black;
}

p1 {
    font-size: 13px;
}

.copyright {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    line-height: 1.1em;
    padding: 0 .7em;
}

.col {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-end;
    font-family: 'zilla';
    color: rgb(143, 140, 138);
    font-size: 23px;
}

.social {
    max-width: 30px;
}

a {
}

p {
    font-size: 19px;
    margin: 0;
    line-height: 1.3em;
}

.footer-items {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.name {
    font-family: 'Homemade';
    font-size: 30px;
    font-weight: 550;
    color: black;
}

.footer-width {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}

.footer-container {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  background-image: linear-gradient(80deg,#dfdada, #ecf0f7bd, #cbdaf0d5, #acb5cf7e);
    padding: 1em .7em;
    width: 100%;
}
</style>
