import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import FineArts from '@/views/FineArts.vue';
import Design from '@/views/Design.vue';
import Resume from '@/views/Resume.vue';
import ProjectLiberty from '@/views/design/ProjectLiberty.vue';
import ProjectAuntNees from '@/views/design/ProjectAuntNees.vue';
import ProjectMerit from '@/views/design/ProjectMerit.vue';
import ProjectBonAppetit from '@/views/design/ProjectBonAppetit.vue';

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/fine-arts',
      name: 'FineArts',
      component: FineArts
    },
    {
      path: '/design',
      name: 'Design',
      component: Design
    },
    {
      path: '/resume',
      name: 'Resume',
      component: Resume
    },
    {
        path: '/design/project-liberty',
        name: 'ProjectLiberty',
        component: ProjectLiberty,
    },
    {
        path: '/design/project-aunt-nees',
        name: 'ProjectAuntNees',
        component: ProjectAuntNees,
    },
    {
      path: '/design/project-merit',
      name: 'ProjectMerit',
      component: ProjectMerit,
    },
    {
        path: '/design/project-bon-appetit',
        name: 'ProjectBonAppetit',
        component: ProjectBonAppetit,
    }
  ]
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      } else if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    }
  })
  
  export default router